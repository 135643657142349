import { styled } from '@mui/material';
import { CircleIcon } from '../../../assets';
import Banner from '../../banner/Banner';
import BannerLayer from '../../banner-layer/BannerLayer';
import { Companies } from '../../../models/domain/enum';
import { getCompany } from '../../../utils/company';

const getNavigationDotsStyles = (
  company: Companies,
  theme: any,
  forceTextInsideImageAtSmallScreenForDefaultPosition?: boolean
) => {
  switch (company) {
    case Companies.SIRMAN:
      return {
        [theme.breakpointsMediaQuery.down('md')]: {
          marginTop: forceTextInsideImageAtSmallScreenForDefaultPosition
            ? `-${theme.spacings.unit(8)}`
            : `${theme.spacings.unit(4)}`,
        },
        marginTop: `${theme.spacings.unit(4)}`,
      };
    case Companies.FELSINEA:
      return {
        transform: 'translate(0, -150%)',
      };
  }
};

export const NavigationDots = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'dotsAlignment',
})(
  ({
    theme,
    dotsAlignment,
    forceTextInsideImageAtSmallScreenForDefaultPosition,
  }) => ({
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 10,
    textAlign: dotsAlignment,
    ...getNavigationDotsStyles(
      getCompany(),
      theme,
      forceTextInsideImageAtSmallScreenForDefaultPosition
    ),
  })
);

export const StyledCircleIcon = styled(CircleIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  color: active ? theme.colors.primary : theme.colors.dot,
  cursor: 'pointer',
  width: theme.spacings.unit(3),
  height: theme.spacings.unit(3),
  marginRight: theme.spacings.unit(2),
  '&:hover': {
    color: active ? theme.colors.activeDot : theme.colors.dotHover,
  },
}));

export const CarouselWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '.alice-carousel': {
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  '.alice-carousel__stage': {
    padding: 0,
    margin: 0,
    height: '100%',
    display: 'flex',
  },
  '& .alice-carousel__wrapper': {
    height: '100%',
  },
  '.alice-carousel__stage-item': {
    flex: '1 0 auto',
    height: 'auto',
  },
}));

export const StyledBanner = styled(({ className, itemId, ...props }: any) => (
  <Banner className={className} {...props} id={itemId} />
))(() => ({}));

export const StyledBannerLayer = styled(
  ({ className, itemId, ...props }: any) => (
    <BannerLayer className={className} {...props} id={itemId} />
  )
)(() => ({}));
