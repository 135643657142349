import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import CarouselSliderTop from '../../../components/carousel/carousel-slider-top/CarouselSliderTop';
import { StyledContentWrapper } from './SliderTop.style';
import { SliderConfigs } from '../../../models/domain/interfaces';
import { defaultSliderConfigs } from '../../../utils/carousels';

interface SliderTopProps {
  cards: any;
  size: any;
  sliderConfigs?: SliderConfigs;
  layer?: boolean;
}

const SliderTop: React.FC<any> = ({ data }: { data: SliderTopProps }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('sm'));
  const [cards, setCards] = useState<any>(data?.cards || []);
  const [autoPlay, setAutoPlay] = useState<any>(
    isDesktop
      ? data?.sliderConfigs?.autoPlay || defaultSliderConfigs?.autoPlay
      : false
  );

  useEffect(() => {
    const cardss = data.cards?.map((card: any) => {
      const image =
        typeof window === 'undefined' || isDesktop
          ? card.image
          : card.mobileImage?.data
            ? card.mobileImage
            : card.image;

      return {
        ...card,
        image,
      };
    });
    setCards(cardss);
  }, [data.cards, isDesktop]);

  useEffect(() => {
    if (isDesktop) {
      setAutoPlay(
        data?.sliderConfigs?.autoPlay || defaultSliderConfigs?.autoPlay
      );
    } else {
      setAutoPlay(false);
    }
  }, [data?.sliderConfigs?.autoPlay, isDesktop]);

  return (
    <StyledContentWrapper>
      <CarouselSliderTop
        cards={cards}
        sliderConfigs={{
          ...(data?.sliderConfigs ?? defaultSliderConfigs),
          autoPlay: autoPlay,
        }}
      />
    </StyledContentWrapper>
  );
};

export default SliderTop;
