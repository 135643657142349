import { styled } from '@mui/material';
import { HeaderCoverResizeType } from '../../models/enum';
import { StyledLink } from '../../styles/shared';
import ButtonComponent from '../button/Button';
import { Companies } from '../../models/domain/enum';
import { getCompany } from '../../utils/company';

export const OverlayStyled = styled('div', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ overlay }) => ({
  backgroundColor: `${overlay ? overlay + '80' : 'transparent'}`,
  gridArea: '1 / 1 / 1 / 1',
  position: 'relative',
}));

const getMarginContent = (company: Companies, theme: any) => {
  switch (company) {
    case Companies.SIRMAN:
      return '0 auto';
    case Companies.FELSINEA:
      return `0 auto ${theme.spacings.unit(50 / 4)} auto`;
  }
};

export const ContentDivision = styled('div', {
  shouldForwardProp: (prop) => !['maxDimension'].includes(prop as string),
})(({ theme, maxDimension }) => ({
  zIndex: 2,
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '62%',
  justifyContent: 'center',
  margin: getMarginContent(getCompany(), theme),
  [theme.breakpointsMediaQuery.down('md')]: {
    maxWidth: 'inherit',
  },
  padding: '0px',
  [theme.breakpointsMediaQuery.up('sm')]: {
    padding: `
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(9)} 
      ${theme.spacings.unit(10)} 
      ${theme.spacings.unit(9)}
    `,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: '0px',
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    padding: '0px',
  },
}));

export const ImageContainerStyled = styled('div', {
  shouldForwardProp: (prop) => !['headerCoverResize'].includes(prop as string),
})(({ theme }) => ({
  gridArea: '1 / 1 / 1 / 1',
  '> span': {
    height: '100% !important',
  },
}));

export const ImageContainerDefaultStyled = styled('div', {
  shouldForwardProp: (prop) =>
    !['headerCoverResize', 'url'].includes(prop as string),
})(({ theme, headerCoverResize, url }) => ({
  position: 'relative',
  display: 'block',
  backgroundColor: theme.colors.backgroundColorFallbackBanner,
  [theme.breakpointsMediaQuery.down('sm')]: {
    minHeight: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return url === 'true' ? '30vh' : 0;
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    height: '100%',
    maxHeight: 'none',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    zIndex: -3,
  },
}));

export const CoverContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !['headerCoverResize', 'url'].includes(prop as string),
})(({ theme, headerCoverResize, url }) => ({
  position: 'relative',
  display: 'grid',
  width: '100%',
  color: theme.colors.primaryText,
  '&:after': {
    opacity: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: -1,
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    minHeight: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return url === 'true' ? theme.spacings.unit(130) : 'auto';
        case HeaderCoverResizeType.CONTAIN:
          return '100%';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
    color: theme.colors.white,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    height: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return url === 'true' ? theme.spacings.unit(130) : 'auto';
        case HeaderCoverResizeType.CONTAIN:
          return '100%';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    height: (() => {
      switch (headerCoverResize) {
        default:
        case HeaderCoverResizeType.DEFAULT_COVER:
          return url === 'true' ? theme.spacings.unit(130) : 'auto';
        case HeaderCoverResizeType.CONTAIN:
          return 'auto';
        case HeaderCoverResizeType.HIDDEN:
          return 0;
      }
    })(),
  },
}));

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  position: 'relative',
  padding: 0,
  display: 'flex',
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: lightContrast ? theme.colors.white : theme.colors.primaryText,
    gridArea: '1 / 1 / 1 / 1',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: 0,
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    padding: 0,
  },
}));

export const Title = styled('h1', {
  shouldForwardProp: (prop) =>
    ![
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  margin: 0,
  marginTop: theme.fonts.utility.size(6),
  wordBreak: 'break-word',

  [theme.breakpointsMediaQuery.up('sm')]: {
    fontSize: fontSize
      ? `${fontSize * 0.5}px`
      : theme.fonts.utility.size((40 * 0.5) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 6 * 0.5) * 0.5}px`
      : theme.fonts.utility.size((46 * 0.5) / 2),
    fontWeight: fontWeight ? (fontWeight as number) : 600,
  },

  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: fontSize
      ? `${fontSize * 0.6}px`
      : theme.fonts.utility.size((40 * 0.6) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 6 * 0.6) * 0.6}px`
      : theme.fonts.utility.size((46 * 0.6) / 2),
  },

  [theme.breakpointsMediaQuery.up('lg')]: {
    fontSize: fontSize
      ? `${fontSize * 0.8}px`
      : theme.fonts.utility.size((40 * 0.8) / 2),
    lineHeight: fontSize
      ? `${(fontSize + 6 * 0.8) * 0.8}px`
      : theme.fonts.utility.size((46 * 0.8) / 2),
  },

  [theme.breakpointsMediaQuery.up('xl')]: {
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(40 / 2),
    lineHeight: fontSize
      ? `${fontSize + 6}px`
      : theme.fonts.utility.size(46 / 2),
  },

  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize
      ? `${fontMobileSize}px`
      : theme.fonts.utility.size(30 / 2),
    lineHeight: fontMobileSize
      ? `${fontMobileSize + 6}px`
      : theme.fonts.utility.size(34 / 2),
    fontWeight: fontMobileWeight ? fontMobileWeight : 600,
  },
}));

export const Description = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'textLineLimit',
      'fontSize',
      'fontMobileSize',
      'fontMobileWeight',
      'fontMobileWeight',
    ].includes(prop as string),
})(
  ({
    theme,
    textLineLimit,
    fontSize,
    fontMobileSize,
    fontMobileWeight,
    fontWeight,
  }) => ({
    maxWidth: theme.spacings.unit(600 / 4),
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(18 / 2),
    paddingRight: theme.spacings.unit(20 / 4),
    margin: `${theme.spacings.unit(22 / 4)} 0 0 0 `,
    span: {
      display: 'inline-block',
      lineHeight: theme.fonts.utility.lineHeight(26 / 4),
      ...(textLineLimit && {
        maxHeight: `calc(${theme.spacings.unit(30 / 4)} * 3)`,
        overflowY: 'hidden',
      }),
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      fontSize: fontMobileSize
        ? `${fontMobileSize}px`
        : theme.fonts.utility.size(18 / 2),
      fontWeight: fontMobileWeight ? fontMobileWeight : 300,
      lineHeight: fontMobileSize
        ? `${fontMobileSize + 6}px`
        : theme.fonts.utility.size(26 / 2),
    },
    [theme.breakpointsMediaQuery.up('sm')]: {
      fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(18 / 2),
      lineHeight: fontSize
        ? `${fontSize + 6}px`
        : theme.fonts.utility.size(26 / 2),
      fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
    },
  })
);

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  marginTop: theme.spacings.unit(30 / 4),
  [theme.breakpointsMediaQuery.up('md')]: {
    flexGrow: 0,
  },
}));

export const Button = styled(({ text, style, className, ...props }: any) => {
  return (
    <ButtonComponent
      text={text}
      styleBtn={style}
      className={className}
      {...props}
    ></ButtonComponent>
  );
})(({ theme, fontSize, fontMobileSize, fontMobileWeight, fontWeight }) => ({
  border: 0,
  padding: theme.spacings.unit(4),
  alignSelf: 'center',
  textAlign: 'center',
  '&:hover': {
    background: theme.colors.primary,
    border: 0,
    color: theme.colors.white,
    cursor: 'pointer',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    color: theme.colors.white,
    border: 0,
    fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(18 / 2),
    lineHeight: fontSize
      ? `${fontSize + 6}px`
      : theme.fonts.utility.size(26 / 2),
    fontWeight: fontWeight ? (fontWeight as number) : 600,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    alignSelf: 'start',
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: fontMobileSize
      ? `${fontMobileSize}px`
      : theme.fonts.utility.size(18 / 2),
    lineHeight: fontMobileSize
      ? `${fontMobileSize + 6}px`
      : theme.fonts.utility.size(26 / 2),
    fontWeight: fontMobileWeight ? fontMobileWeight : 600,
  },
}));

export const TextWrapper = styled('div', {
  shouldForwardProp: (prop) => !['lightContrast'].includes(prop as string),
})(({ theme, lightContrast }) => ({
  fontSize: theme.fonts.utility.size(7),
  letterSpacing: theme.spacings.unit(0.0875),
  maxWidth: theme.spacings.unit(332.5),
  margin: `${theme.spacings.unit(10)} ${theme.spacings.unit(9)}`,
  color: lightContrast ? theme.colors.white : theme.colors.paragraphText,
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(8),
    margin: `${theme.spacings.unit(10)} auto ${theme.spacings.unit(18.5)} auto`,
  },
}));

export const BreadcrumbLink = styled(StyledLink)(() => ({
  fontSize: 'inherit',
}));

export const TrapezoidStyled = styled('div')(({ theme, fill }) => ({
  zIndex: 1,
  width: '51%',
  height: '100%',
  position: 'relative',
  svg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    path: {
      fill: fill ? fill : '#000',
    },
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    width: '100%',
    padding:
      '0 calc(36px + env(safe-area-inset-right)) 0 calc(36px + env(safe-area-inset-left))',
    svg: {
      display: 'none',
    },
  },
}));
