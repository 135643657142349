import { styled } from '@mui/material';
import { MobilePaddingLessContentWrapper } from '../../../styles/shared';
import { getCompany } from '../../../utils/company';
import { Companies } from '../../../models/domain/enum';

const company = getCompany();

const getStyledContentWrapperStyles = (theme: any) => {
  switch (company) {
    case Companies.SIRMAN:
      return {
        marginBottom: theme.spacings.unit(12),
      };
    case Companies.FELSINEA:
      return {
        marginBottom: 0,
      };
    default:
      return {
        marginBottom: theme.spacings.unit(16),
      };
  }
};

export const StyledContentWrapper = styled(MobilePaddingLessContentWrapper)(
  ({ theme }) => ({
    ...getStyledContentWrapperStyles(theme),
  })
);
