import React, { useEffect, useState } from 'react';
import router from 'next/router';

// Components
import Markdown from '../markdown/Markdown';

// Utils
import { correctHeightProportion } from '../../utils/image';
import { getStrapiMedia } from '../../utils/media';
import {
  capitalizeOnlyFirstLetter,
  hexToRgb,
  rgbDataURL,
} from 'corporate-utils';

// Models
import {
  HeaderCoverResizeType,
  HeaderDescriptionPositionType,
} from '../../models/enum';
import {
  Button,
  ButtonWrapper,
  ContentDivision,
  CoverContainer,
  Description,
  ImageContainerDefaultStyled,
  ImageContainerStyled,
  OverlayStyled,
  TextWrapper,
  Title,
  TrapezoidStyled,
  Wrapper,
} from './BannerLayer.style';
import { useMediaQuery, useTheme } from '@mui/material';
import { TrapezoidLayer } from '../../assets';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';
import { DEFAULT_IMAGE } from '../../models/domain/const';

export interface BannerProps {
  title?: string;
  description?: string;
  titleFontSize?: number;
  descriptionFontSize?: number;
  titleMobileFontSize?: number;
  descriptionMobileFontSize?: number;
  titleFontWeight?: number;
  titleMobileFontWeight?: number;
  descriptionFontWeight?: number;
  descriptionMobileFontWeight?: number;
  image?: any;
  button?: {
    text?: string;
    textFontSize?: number;
    textMobileFontSize?: number;
    textMobileFontWeight?: number;
    textFontWeight?: number;
    url?: string;
    type?: 'primary' | 'secondary';
  };
  overlayColor?: string;
  breadCrumb?: {
    links: {
      href: string;
      name: string;
      disabled?: boolean;
      color?: string;
    }[];
    pageActive: {
      name: string;
      color?: string;
    };
  };
  className?: string;
  fixedHeight?: boolean;
  id?: string | number;
  textLineLimit?: boolean;
  fullContentHeight?: boolean;
  forceHideDescription?: boolean;
  forceHideButton?: boolean;
  forceHideBreadcrumb?: boolean;
  headerDescriptionPosition?: 'default' | 'under' | 'hidden';
  forceHideTitle?: boolean;
  forceDescriptionToBeWhite?: boolean;
  forceDescriptionToBeBlack?: boolean;
  headerCoverResize?: 'default_cover' | 'contain' | 'hidden';
  noLazyLoading?: boolean;
  layerColor?: string;
  isPrerenderRequest: boolean;
}

const BannerBlackLayer: React.FC<BannerProps> = ({
  title,
  description,
  descriptionFontSize,
  descriptionMobileFontSize,
  descriptionFontWeight,
  descriptionMobileFontWeight,
  button,
  image,
  overlayColor,
  id,
  textLineLimit = false,
  titleFontSize,
  titleMobileFontSize,
  titleFontWeight,
  titleMobileFontWeight,
  forceDescriptionToBeBlack,
  forceDescriptionToBeWhite,
  forceHideTitle,
  forceHideDescription,
  forceHideButton,
  forceHideBreadcrumb,
  headerDescriptionPosition = 'default',
  headerCoverResize = 'default_cover',
  noLazyLoading,
  layerColor,
  isPrerenderRequest,
}: BannerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('sm'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render
  useEffect(() => {
    setRerender(Date.now());
  }, []);
  headerCoverResize = headerCoverResize || 'default_cover';
  headerDescriptionPosition = headerDescriptionPosition || 'default';

  const descriptionId = `bannerDescription__${id}`;

  const atLeastSomethingInDescriptionIsTrue = () => {
    return (
      !forceHideButton ||
      !forceHideDescription ||
      !forceHideTitle ||
      !forceHideBreadcrumb
    );
  };
  const Trapezoid: any = TrapezoidLayer;
  return (
    <>
      {/* Image and description */}
      <CoverContainer
        headerCoverResize={headerCoverResize}
        url={(!!image?.data?.attributes?.url).toString()}
      >
        {/* COVER IMAGE */}
        {/* When image is cover */}
        <ImageContainerDefaultStyled
          headerCoverResize={headerCoverResize}
          url={(!!image?.data?.attributes?.url).toString()}
        >
          {image &&
            image.data &&
            image.data.attributes &&
            image?.data?.attributes?.url &&
            (HeaderCoverResizeType.DEFAULT_COVER ===
              (headerCoverResize as HeaderCoverResizeType) ||
              !headerCoverResize) && (
            <ImageWithFallback
              layout="fill"
              objectFit="cover"
              placeholder={noLazyLoading ? 'empty' : 'blur'}
              blurDataURL={
                overlayColor
                  ? rgbDataURL(
                    hexToRgb(overlayColor)?.[0],
                    hexToRgb(overlayColor)?.[1],
                    hexToRgb(overlayColor)?.[2]
                  )
                  : rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )
              }
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              priority={noLazyLoading}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          )}
        </ImageContainerDefaultStyled>

        {/* COVER IMAGE */}
        {/* When image is contain */}

        {image &&
          image?.data &&
          image?.data?.attributes &&
          image?.data?.attributes?.url &&
          (HeaderCoverResizeType.CONTAIN ===
            (headerCoverResize as HeaderCoverResizeType) ||
            !headerCoverResize) && (
          <ImageContainerStyled>
            <ImageWithFallback
              width={'1920'}
              height={
                correctHeightProportion(
                  image?.data?.attributes?.height,
                  image?.data?.attributes?.width
                ) || '600'
              }
              placeholder={noLazyLoading ? 'empty' : 'blur'}
              blurDataURL={
                overlayColor
                  ? rgbDataURL(
                    hexToRgb(overlayColor)?.[0],
                    hexToRgb(overlayColor)?.[1],
                    hexToRgb(overlayColor)?.[2]
                  )
                  : rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )
              }
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              priority={noLazyLoading}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          </ImageContainerStyled>
        )}

        {/* Overlay cover */}
        {(typeof window === 'undefined' || isDesktop) && overlayColor ? (
          <OverlayStyled overlay={overlayColor}></OverlayStyled>
        ) : (
          <></>
        )}

        {/* Description inside image */}
        {HeaderDescriptionPositionType.DEFAULT ===
          (headerDescriptionPosition as HeaderDescriptionPositionType) &&
        atLeastSomethingInDescriptionIsTrue() ? (
            <Wrapper
              lightContrast={
                !image?.data?.attributes?.url
                  ? false
                  : true && !forceDescriptionToBeBlack
              }
            >
              <TrapezoidStyled fill={layerColor ? layerColor : '#000'}>
                <Trapezoid preserveAspectRatio={'none'} />
                <ContentDivision>
                  {title && !forceHideTitle && (
                    <Title
                      fontSize={titleFontSize}
                      fontMobileSize={titleMobileFontSize}
                      fontWeight={titleFontWeight}
                      fontMobileWeight={titleMobileFontWeight}
                    >
                      <Markdown children={title}></Markdown>
                    </Title>
                  )}
                  {description && !forceHideDescription && (
                    <Description
                      textLineLimit={textLineLimit}
                      fontSize={descriptionFontSize}
                      fontMobileSize={descriptionMobileFontSize}
                      fontWeight={descriptionFontWeight}
                      fontMobileWeight={descriptionMobileFontWeight}
                    >
                      <Markdown id={descriptionId} children={description} />
                    </Description>
                  )}
                  {button && button.url && button.text && !forceHideButton && (
                    <ButtonWrapper>
                      <Button
                        onClick={(e: { preventDefault: () => void }): void => {
                          e.preventDefault();
                          button?.url && router.push(button?.url);
                        }}
                        text={capitalizeOnlyFirstLetter(button.text)}
                        type={button?.type || 'primary'}
                        fontSize={button?.textFontSize}
                        fontMobileSize={button?.textMobileFontSize}
                        fontWeight={button?.textFontWeight}
                        fontMobileWeight={button?.textMobileFontWeight}
                      ></Button>
                    </ButtonWrapper>
                  )}
                </ContentDivision>
              </TrapezoidStyled>
            </Wrapper>
          ) : (
            <></>
          )}
      </CoverContainer>

      {/* Description under the image */}
      {HeaderDescriptionPositionType.UNDER ===
        (headerDescriptionPosition as HeaderDescriptionPositionType) &&
      atLeastSomethingInDescriptionIsTrue() ? (
          <TextWrapper lightContrast={false || forceDescriptionToBeWhite}>
            <ContentDivision maxDimension={true}>
              {HeaderDescriptionPositionType.UNDER ===
            (headerDescriptionPosition as HeaderDescriptionPositionType) ? (
                  <>
                    {title && !forceHideTitle && (
                      <Title
                        fontSize={titleFontSize}
                        fontMobileSize={titleMobileFontSize}
                        fontWeight={titleFontWeight}
                        fontMobileWeight={titleMobileFontWeight}
                      >
                        <Markdown children={title}></Markdown>
                      </Title>
                    )}
                    {description && !forceHideDescription && (
                      <Description
                        textLineLimit={textLineLimit}
                        fontSize={descriptionFontSize}
                        fontMobileSize={descriptionMobileFontSize}
                        fontWeight={descriptionFontWeight}
                        fontMobileWeight={descriptionMobileFontWeight}
                      >
                        <Markdown id={descriptionId} children={description} />
                      </Description>
                    )}
                    {button && button.url && button.text && !forceHideButton && (
                      <ButtonWrapper>
                        <Button
                          onClick={(e: { preventDefault: () => void }): void => {
                            e.preventDefault();
                            button?.url && router.push(button?.url);
                          }}
                          text={capitalizeOnlyFirstLetter(button.text)}
                          type={button?.type || 'primary'}
                          fontSize={button?.textFontSize}
                          fontMobileSize={button?.textMobileFontSize}
                          fontWeight={button?.textFontWeight}
                          fontMobileWeight={button?.textMobileFontWeight}
                        >
                          {capitalizeOnlyFirstLetter(button.text)}
                        </Button>
                      </ButtonWrapper>
                    )}
                  </>
                ) : (
                  <></>
                )}
            </ContentDivision>
          </TextWrapper>
        ) : (
          <></>
        )}
    </>
  );
};

export default BannerBlackLayer;
